export default {
  "home": "Accueil",
  "homeOne": "Première maison",
  "homeTwo": "Maison deux",
  "homeThree": "Maison trois",
  "copyrightText": "© Tous droits réservés | Le site web Lotto Amigo est opéré par Black Danes LLC.",
  "close": "Fermer",
  "userProfile": "Compte d'utilisateur",
  "lotteries": "Loteries",
  "subscriptionsMenu": {
    "label": "Abonnements",
    "create": "Créer un abonnement",
    "my": "Mes abonnements",
  },
  "freeLotteries": "Loteries Gratuites",
  "support": "Assistance",
  "myTickets": "Mes billets",
  "powerball": "Powerball",
  "megamillions": "Mega Millions",
  "group": "Groupe",
  "groups": "Groupes",
  "results": "Résultats",
  "shop": "Boutique",
  "productDetails": "détails du produit",
  "cart": "Chariot",
  "checkout": "Check-out",
  "payment": "Paiement",
  "fashion": "Mode",
  "gadgets": "Gadgets",
  "accessories": "Accessories",
  "categories": "Catégories",
  "men": "Männer",
  "jean": "Jean",
  "jackets": "Vestes",
  "shirt": "Chemise",
  "tShirt": "T-shirt",
  "women": "Femmes",
  "dress": "Robe",
  "dresses": "Robes",
  "headphone": "casque de musique",
  "smartphone": "Téléphone intelligent",
  "watch": "Regarder",
  "speaker": "Orateur",
  "laptopAccessories": "Accessoires pour ordinateur portabl",
  "belts": "Ceintures",
  "jewellery": "Bijoux",
  "purse": "Bourse",
  "pages": "Pages",
  "about": "À propros",
  "aboutLottoAmigo": "À propos de Lotto Amigo",
  "termAndCondition": "Termes et conditions",
  "privacyPolicy": "Politique de confidentialité",
  "privacyPolicySubTitle": "Avec nous, vos données sont en sécurité",
  "blogDetail": "Détail du blog",
  "faq": "FAQ",
  "Page": "Page",
  "404Page": "404 Page",
  "session": "Session",
  "signIn": "Connexion",
  "signOut": "Se déconnecter",
  "register": "Registre",
  "password": "Mot de passe",
  "email": "E-mail",
  "rememberMe": "Se sourvenir de moi",
  "forgotPassword": "Mot de passe oublié",
  "dontHaveAccount": "Vous n'avez pas de compte?",
  "clickHereCreateAccount": "Cliquez ici pour en créer un:",
  "grid": "Grille",
  "grids": "Grilles",
  "parts": "Parts",
  "select": "Sélectionnez",
  "login": "Connexion",
  "signup": "S'enregistrer",
  "days": "jours",
  "timesUp": "Temp écoulé!",
  "hi": "Bonjour",
  "play": "Jouer",
  "million": "Millions",
  "billion": "Milliards",
  "notAvailable": "Non disponible",
  "cancel": "Annuler",
  "yes": "Oui",
  "no": "Non",
  "understand": "J'ai compris",
  "interacEmail": "pay@serviceamigo.com",
  "maintenance": "Nous sommes fermés pour cause de maintenance. Nous sommes désolés des inconvénients.<br><b><u>Nous serons de retour le 20 octobre.</u></b>",
  "newEmail": "L'addresse email pour le paiement Interac a changé. Veuillez utiliser:",
  "copyToClipboard": "Copier dans le presse-papiers",
  "copied": "Copié!",
  "monthsShortNames": {
    "1": "Janv",
    "2": "Févr",
    "3": "Mars",
    "4": "Avr",
    "5": "Mai",
    "6": "Juin",
    "7": "Juil",
    "8": "Août",
    "9": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Déc",
  },
  "language": {
    "en": "Anglais",
    "fr": "Français"
  },
  "languageShort": {
    "en": "EN",
    "fr": "FR"
  },
  "sideBar": {
    "english": "English",
    "fench": "Français",
    "customerInfo": "Information Client",
    "settings": "Paramètres",
    "tools": "Outils",
  },
  "signinPage": {
    "title": "Entrez vous informations",
    "firstname": "Prenom*",
    "lastname": "Nom de famille*",
    "dob": "Date de naissance* (18+)",
    "email": "E-mail*",
    "phone": "Téléphone",
    "password": "Entrez le mot de passe*",
    "retypePassword": "Entrez le mot de passe de nouveau*",
    "signup": "S'enregistrer",
    "haveAccount": "Vous avez un compte?",
    "createAccount": "Créer un compte",
    "signin": "Connectez vous",
    "seeConditions": "(Voir les conditions)",
    "conditions": "Conditions",
    "acceptConditions": "J'accepte les conditions",
    "newletters": "J'aimerais recevoir les info lettre à propos des nouveautés, des promotions et des tirages à venir.",
    "sms": "J'aimerais recevoir les SMS à propos des nouveautés, des promotions et des tirages à venir.",
    "error": {
      "auth/email-already-exists": "Ce e-mail existe déjà",
      "auth/invalid-email": "E-mail est invalid",
      "auth/operation-not-allowed": "La création de compte n'est pas disponible",
      "auth/weak-password": "Le mot de passe doit être d'au moins 6 caractères",
      "auth/user-disabled": "L'usagé est déactivé, contactez le support",
      "auth/user-not-found": "Usagé non trouvé",
      "auth/wrong-password": "Le mot de passe est invalid",
      "auth/too-many-requests": "L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Vous pouvez le restaurer immédiatement en réinitialisant votre mot de passe ou vous pouvez réessayer plus tard.",
      "auth/accepted-char": "Uniquement les lettres, chiffres et caractères spéciaux comme @$!%*?&()#+-_= sont acceptés",
      "auth/invalid-password": "Mot de passe invalide. Le mot de passe doit être d'au moins 6 caractères et uniquement les lettres, chiffres et caractères spéciaux comme @$!%*?&()#+-_= sont acceptés",
      "fieldNotEmpty": "Ce champs ne peut pas être vide",
      "emailRequired": "Un e-mail est requis",
      "passwordRequired": "Un mot de passe est requis",
      "passwordDifferent": "Le mot de passe ne correspond pas au précédent",
      "dobRequired": "La date de naissance est requise",
      "dobMinor": "Vous devez avoir plus de 18 ans",
      "currentPassword": "Mot de passe actuel non valide",
      "unknown": "Une erreur est survenue, vérifiez vos informations ou contactez le support."
    }
  },
  "registerPage": {
    "emailConfirmation": "Un courriel de confirmation a été envoyé. Svp, confirmez votre adresse courriel. Si vous le trouvez pas, regardez dans votre dossier de pourriel.",
    "language": "Langue de communication",
    "currency": "Devise"
  },
  "actionPage": {
    "title": "Réinitialisez votre mot de passe",
    "titleVerif": "Votre email a été vérifié.",
    "home": "Aller à la page principal",
    "submit": "Soumettre",
    "error": {
      "auth/expired-action-code": "Code de mise à jour expiré",
      "auth/invalid-action-code": "Code mise à jour non valide",
      "auth/user-disabled": "L'usagé est déactivé, contactez le support",
      "auth/user-not-found": "Usagé non trouvé",
      "auth/weak-password": "Mauvais mot de passe"
    }
  },
  "editProfilePage": {
    "title": "Modifier l'information de profile",
    "firstName": "Prénom",
    "lastName": "Nom de famille",
    "phoneNo": "Numéros de téléphone",
    "language": "Langue",
    "currency": "Devise"
  },
  "forgotPage": {
    "title": "Mot de passe oublié ?",
    "subtitle": "Pas de problème",
    "email": "Entrez votre e-mail*",
    "retypeEmail": "Enter Your Email à nouveau*",
    "submit": "Envoyer",
    "linkByEmail": "Un email pour réinitialiser votre mot de passe à été envoyé",
    "error": {
      "auth/invalid-email": "E-mail invalid",
      "auth/user-not-found": "Pas de compte associé à ce e-mail",
      "auth/argument-error": "E-mail invalid",
      "emailRequired": "Un e-mail est requis",
      "undefined": "Une erreur est survenue"
    }
  },
  "faqPage": {
    "title": "Foire aux questions",
    "desc": "Obtenez une réponse rapidement aux questions les plus fréquemment posées.",
    "noAnswerQuestion": "Vous n'avez pas trouvé votre réponse ici?",
    "noAnswerDesc": "Si vous avez toujours une question, soumettez une demande à l'équipe de support par le formulaire de contact. Nous serons heureux de vous entendre.",
    "noAnswerBtn": "Soumettre une demande"
  },
  "contactPage": {
    "header": "Contactez-nous",
    "title": "Information de contact",
    "desc": "Si vous avez des problèmes, suggestions ou commentaires, écrivez nous. Choisissez une option de communication. Il nous fera plaisir de vous répondre.",
    "chatTitle": "Clavardez avec notre équipe",
    "chatDesc": "Notre équipe est prête à vous répondre aussi rapidement que possible par clavardage. Cliquez sur l'icon en bas à droite de votre écran et démarrez une conversation!",
    "writeToUs": "Écrivez nous",
    "name": "Nom",
    "nameField": "Votre nom",
    "email": "Email",
    "emailField": "Votre Email",
    "message": "Message",
    "messageField": "Message",
    "sendBtn": "Envoyer",
    "emailSent": "Message envoyé!",
    "orderInProcessWarning": "Si vous nous contactez à propos d'une commande dont vous ne voyez pas encore votre billet, veillez notez que cette commande est en cours et vous aurez votre billet lorsqu'il sera acheté.",
    "validation": {
      "emailRequired": "Le courriel est requis",
      "emailValid": "Le courriel est non valide",
      "notEmpty": "Ce champs ne peut pas être vide"
    }
  },
  "notifications": {
    "header": "Nouvelles Notifications",
    "bannerMsg": {
      "winner": "Il semble que vous avez gagné de l'argent! Allez lire vos message!",
      "genericMessage": "Vous avez un message, cliquez ici pour le lire.",
    },
    "dialogMsg": {
      "win": "Félicitation, Vous avez gagné {amount}!!",
      "contact": "S.v.p. contactez le support, Lotto Amigo a besoin de vous parler.",
    }
  },
  "productsPage": {
    "addGrid": "Ajouter une grille",
    "addToCart": "Ajouter au panier",
    "addAndCheckout": "Ajouter et passer à la caisse",
    "processOrder": "Ajouter la commande",
    "updateCart": "Mettre à jour",
    "combination": "Combinaisons",
    "singleTicket": "Tout les combinaisons sur un seul ticket",
    "productAdded": "Billet ajouté au panier",
    "grids": "Grilles",
    "gridSelectedNone": "Selectionner {selected} numéros",
    "gridSelectedAll": "Tous les numéros sélectionnés",
    "gridSelected": "Selectionner {selected_left} numéros de plus",
    "soldPrice": "Prix vendu",
    "useCredit": "Utiliser le crédit",
    "availableCredit": "Crédit disponible",
    "customerTicket": "Billet du client",
    "customer": "Client",
    "customerId": "Id",
    "multiplier": {
      "megamillions": {
        "name": "Megaplier",
        "desc": "Le Megaplier multiplie tous les prix, hors jackpot, par 2, 3, 4 ou 5, en fonction du numéro Megaplier tiré au sort."
      },
      "powerball": {
        "name": "Power Play",
        "desc": "Le Power Play permet de doubler le prix de 2ième rang, c'est à dire 2 millions $ ! Multipliez également, jusqu’à 10, les autres catégories de prix, en fonction du tirage et de la taille du jackpot."
      }
    },
    "quickpicksNumbers": "Combinaisons Quickpicks",
    "multiplaySingle": "Tirage Simple",
    "multiplayMultiple": "{num} Tirages (économisez {save}% sur les frais)",
    "multiPlayDesc": "Jouez pour plusieurs tirages avec le même billet et économisez jusqu'à 25%!",
    "multiplayDraws": "Multi Tirages",
    "multiplayDiscount": "Rabais Multi Tirages",
    "singleDiscount": "Rabais de la vente du printemps",
    "onFees": "sur les frais",
    "numberPlay": "Nombre de tirage",
    "selectionInfo": "Selectionez le mode Quickpicks ou la sélection de grille manuelle.",
    "sections": {
      "quickPicks": {
        "title": "Quickpicks",
        "desc": "Sauver 1$ par combinaisons en choisisant les Quickpicks. Qu'est-ce qu'un Quickicks? Laisser le système choisir vos combinaisons, vous les verrez lorsque vous receverez le billet. Nous sauvons du temps, vous sauvez de l'argent!",
        "product": "combinaisons"
      },
      "ownPicks": {
        "title": "Selectionnez vos propres combinaisons",
        "info": "Minimum de 3 grilles."
      },
    },
    "error": {
      "missing_numbers": "Il manque des numéros dans une de vos grilles.",
      "nothing_to_add": "Aucun produit à ajouter."
    },
    "gridsDisabled": "La grille de sélection est présentement désactivée afin de nous permettre de répondre à la forte demande.",
    "promotion": "PROMOTION",
    "iWantOffer": "Je veux l'offre!",
    "addedToCartDialog": "Votre billet a été ajouté au panier!",
    "continueShopping": "Continuer à magasiner",
    "goToCheckout": "Aller à la caisse",
    "draws": "Tirages",
    "quickpicks": "Quickpicks",
  },
  "freePage": {
    "header": "Loteries Gratuites",
    "subHeader": "De la loterie gratuite? En effet, ce concept unique vous permet de participer à des groupes de loterie tout à fait gratuitement. C'est ça Lotto Amigo! Il vous suffit simplement de cliquer sur le tirage et de participer. Vous serez inscrit pour ce tirage. Revenez participer à chaque tirage!",
    "subscribed": "Enregistré!!",
    "noTicket": "Pas de billet gratuit, revenez demain.",
    "enterHere": "Entrez ici!"
  },
  "playFreePage": {
    "subscribeGroup": "Enregistrez vous à ce groupe",
    "subscribeGroupSmall": "(gratuit)",
    "or": "ou",
    "congratsAlready": "Félicitation, vous êtes enregistré à ce groupe!",
    "buyOwn": "Achetez votre propre billet",
    "congratsDialog": "Félicitation!",
    "buyOwnDialog": "Vous vous êtes inscrit au groupe de loterie. Voulez vous acheter votre propre billet?"
  },
  "myTicketsPage": {
    "noTicket": "Vous n'avez acheté aucun ticket.",
    "noGiftTicket": "Vous n'avez reçu aucun ticket en cadeau.",
    "noOrder": "Vous n'avez aucune commande en cours de traitement.",
    "ordersTab": "Commandes",
    "ticketsTab": "Billets",
    "giftTicketsTab": "Cadeaux",
    "thru": "à",
    "winning": "Gagnant",
    "notWinning": "Non gagnant",
    "waitingResult": "Attente des résultats",
    "cancelOrder": "Êtes-vous certain de vouloir annuler la commande?",
    "orderInProcess": "Vous avez une commande en cours. Il est normal que le billet ne soit pas disponible encore, un membre de l'équipe doit aller l'acheter.",
    "orderInProcessLink": "Cliquez ici pour voir le status de votre commande.",
    "orderInProcessInfo": "Lorsque votre commande sera prête, vous recevrez un email et votre billet sera dans la section Billet.",
    "seeMyTicket": "Voir mon billet",
    "more": "Plus",
    "replay": "Rejouer ce billet",
    "rebate": "rabais:",
    "replayDlgMessage": "Ajouter ce billet à votre panier?",
    "withMultiplier": {
      "powerball": " (avec Power Play)",
      "megamillions": " (avec Megaplier)"
    },
    "header": {
      "lotteryType": "Type de lotterie",
      "numbers": "Numéros",
      "drawDate": "Date du tirage",
      "receiptNumber": "Numéro de reçu",
      "price": "Prix (USD)",
      "priceCurrency": "Prix payé",
      "status": "Status",
      "orderDate": "Date de commande",
      "credit": "Crédit",
      "offerBy": "Offert par",
      "coupon": "Coupon"
    },
    "statusDesc": {
      "sold_stripe": "Paiement confirmé",
      "sold": "Vérification du transfère d'argent",
      "assigned": "Achat des billets",
      "scanned": "Traitement de votre commande",
      "completed": "Livré",
      "canceled": "Annulé"
    }
  },
  "customerHistory": {
    "header": {
      "lotteryType": "Type de lotterie",
      "numbers": "Numéros",
      "drawDate": "Date du tirage",
      "receiptNumber": "Numéro de reçu",
      "price": "Prix (USD)",
      "priceCurrency": "Prix payé",
      "status": "Status",
      "orderDate": "Date de commande",
      "order_type": "Type de commande",
      "credit": "Crédit"
    },
    "seeDetails": "Voir détails"
  },
  "orderViewPage": {
    "orders": "Votre panier",
    "seeYouNumbers": "Afficher vos combinaisons",
    "edit": "Modifier",
    "tax": "Taxe (taxe de la Floride {tax}%)",
    "noRefund": "Je comprends qu'aucun remboursement n'est possible. Pour plus d'information, lire la politique de remboursement.",
    "interac_warning": "Uniquement les paiements par Interac en devises canadiennes (CAD) sont acceptés.",
    "deleteProduct": "Êtes-vous sur de vouloir effacer ce billet?",
    "ticketRemoved": "Le billet est enlevé de votre panier",
    "unavailable": "Le paiement par carte de credit est temporairement non disponible, vous pouvez utiliser Interac!",
    "invalidCoupon": "Coupon invalide.",
    "gift": "Offrir en cadeau?",
    "giftDesc": "Inscrivez le nom et le courriel du destinataire. Un message lui sera envoyé avec ses numéros chanceux.<br /><br />* Le destinataire doit obligatoirement être âgé de <b>18+</b>.",
    "giftName": "Nom*",
    "giftEmail": "Courriel*",
    "giftNote": "Note pour le cadeau",
    "groupDesc": "Inscrivez le nom de votre groupe et celui-ci sera écrit sur le billet.<br /><br />* Les membres du groupes doivents obligatoirement être âgé de <b>18+</b>.",
    "groupName": "Nom du groupe*",
    "important": "Important",
    "transfertNote": "Assurez-vous de tranférer l'argent en utilisant votre email: {email}. Notez également que le bénéficiaire du transfert Interac sera Valerie Thibault."
  },
  "billing": {
    "total": "Total",
    "subtotal": "Sous-Total",
    "tax": "Taxe({tax}%)",
    "checkout": "Passer à la caisse",
    "noProduct": "Aucun billet",
    "fees": "frais",
    "paymentOptions": "Options de paiement",
    "creditCard": "Carte",
    "interac": "Transfert Interac",
    "useCredit": "Utiliser votre crédit ({credit})",
    "credit": "Crédit",
    "coupon": "Coupon",
    "enterCoupon": "Entrer le coupon ici",
    "apply": "Appliquer",
    "couponLabel": "Coupon rabais de {rebate}% (sur le service)",
    "group": "Groupe",
    "ticket_destination": {
      "title": "Destinataire du billet",
      "forYou": "Pour vous",
      "gift": "Offrir en cadeau",
      "group": "Pour un groupe"
    }
  },
  "paymentPage": {
    "title": "Information de paiement",
    "empty": "Votre panier est vide.",
    "goShopping": "Aller faire des achats",
    "interacText1": "Confirmer la commande en cliquant sur le bouton ci-dessous. Une confirmation de commande sera affiché et un e-mail vous sera envoyé.<br/><br/>Les informations pour le transfert Interac sera affiché dans la page de confirmation et dans le e-mail.",
    "interacText2": "Dernière étape: Appuyez sur \"Confirmer la commande\" afin de confirmer votre commande.",
    "interacBtn": "Confirmer la commande",
    "creditCardText1": "Vous avez un credit de {credit} qui sera déduit automatiquement de votre facture.",
    "creditCardText2": "Cliquez sur le bouton pour payer avec votre carte. Lorsque la transaction sera complétée, un e-mail vous sera envoyé.",
    "payNowBtn": "Payer maintenant!",
    "lastStep": "DERNIÈRE ÉTAPE! Confirmer la commande ici:",
    "notice": "<b>E-mail de transfert: <b>pay@serviceamigo.com</b><br />Question: <b>Lotto</b><br />Réponse: <b>Amigo</b><br />",
    "promoCode": "Code Promo",
    "promoCodeDesc": "Entrez votre code promo ici",
    "promoCodeDesc2": "Le code promo sera appliqué sur votre facture lors de votre paiement.",
    "error": {
      "empty": "Ce champs ne doit pas être vide.",
      "length": "Ce champs doit contenir 3 caratère ou plus",
      "send_order": "Une erreur est survenue, essayez plus tard.",
      "UNKNOWN": "Une erreur est survenue, contactez le support.",
      "INVALID_COUPON": "Le code promo est invalide.",
      "EXCEED_HOUR": "Il est trop tard pour acheter un billet de {type_name}. Effacez le de votre panier ou essayez demain.",
      "invalid_gidt_info": "Information invalide pour le cadeau",
      "GRIDS_DISABLED": "La sélection de vos propre numéros est présentement désactivée afin de nous permettre de répondre à la forte demande. Svp, choisir des Quickpicks uniquement.",
      "GRIDS_NEW_RULES": "Si vous choisissez vos propre combinaisons, vous devez avoir 3 grilles et un minimum de 5 tirages.",
      "NO_AUTHORIZATION": "Vous n'avez pas la permission d'effectuer cette action.",
    },
    "interacEmailMsg": "Afin de nous aider à mieux traiter votre commande, veillez nous indiquer le courriel associé à votre compte Interac:",
    "interacEmailLabel": "Courriel Interac ici"
  },
  "finalReceiptPage": {
    "thankyou": "Merci",
    "thanksMessage": "Votre commande a bien été reçu. Un email sera envoyé à {email} lorsque le paiment sera confirmé.",
    "paymentInfo": "Svp, envoyez votre paiement <mark>{amount}</mark> en suivant les directives de paiement ci-dessous :",
    "receiptNumber": "Numéros de reçu",
    "summary": "Sommaire",
    "orderId": "Identifiant de la commande",
    "orderDate": "Date de la commande",
    "orderTotal": "Montant total de la commande",
    "orderDetails": "Détails de votre commande",
    "goToHome": "Retour au menu principal",
    "verifyJunkmail": "* Si vous ne recevez pas de email, regardez votre boîte de courrier indésirable *",
    "orderStatusDesc": "Suivez l'état de votre commande en allant dans « Mes Billets » sous l'onglet « Commandes » ou en cliquant ici:",
    "goToOrders": "Mes Commandes",
    "interacWarningTitle": "IMPORTANT",
    "interacWarningMsg": "Nouvelle adresse e-mail pour les paiements Interac:"
  },
  "ccFinalReceiptPage": {
    "order_completed": "Commande complétée",
    "thanksMessage": "Merci d'avoir utilisé les services de Lotto Amigo. Votre commande sera traîtée et achetée dans les prochaines heure. Un courriel vous sera envoyé lorsque votre commande sera prêt.",
  },
  "accountPopup": {
    "yourCredit": "Votre crédit:"
  },
  "profilePage": {
    "profileInfo": "Information de profile",
    "firstName": "Prénom",
    "lastName": "Nom de famille",
    "dob": "Date de naissance",
    "email": "Courriel",
    "phone": "Numéros de téléphone",
    "credit": "Crédit",
    "orderHistory": "Historique d'achat",
    "userHistory": "Historique de l'utilisateur",
    "actions": "Actions",
    "profile": "Profile",
    "language": "Langue",
    "currency": "Devise",
    "changePassword": "Changer le mot de passe",
    "changeEmail": "Changer le courriel",
    "passwordChanged": "Votre mot de passe a été changé.",
    "emailChanged": "Votre courriel a été changé.",
    "currentPasswordLabel": "Mot de passe actuel",
    "newPasswordLabel": "Nouveau mot de passe",
    "newEmailLabel": "Nouveau courriel",
    "cart": "Panier",
    "update": "Mettre à jour"
  },
  "notificationsPage": {
    "noNotification": "Aucune notification"
  },
  "header": {
    "userProfile": "Votre profile",
    "notifications": "Notifications",
  },
  "aboutPage": {
    "title": "À propos de nous",
  },
  "termAndConditionPage": {
    "title": "Termes et Conditions",
    "subTitle": "S.V.P. lisez et contactez-nous si vous avez des questions.",
  },
  "partnerPage": {
    "header": "Partenaire",
    "promocode": "Code Promo",
    "referralStats": "Statistiques",
    "yesterday": "Hier",
    "thisMonth": "Mois courant",
    "lastMonth": "Mois dernier",
    "total": "Total",
    "today": "Aujourd'hui"
  },
  "resultsPage": {
    "results": "Résultats",
    "date": "Date",
    "jackpot": "Gros Lot",
    "won": "Remporté",
    "rollover": "Remis en jeu",
    "multiplier": {
      "powerball": "Power Play {num}x",
      "megamillions": "Megaplier {num}x"
    }
  },
  "subscriptionsPage": {
    "label": "Abonnements",
    "desc": "Les abonnements vous permettent de participer à plusieurs tirages à la fois. Vous pouvez choisir le nombre de tirages et le nombre de combinaisons pour chaque tirage.",
    "list": "Mes abonnements",
    "create": "Créer un abonnement",
    "update": "Metre à jour l'abonnement",
    "noSubscription": "Vous n'avez pas d'abonnement.",
    "moreInfo": "Un abonnement vous assure de ne jamais manquer un tirage. Choisissez de vous abonner à tous les tirages ou uniquement à ceux dont le jackpot dépasse un certain montant. Votre abonnement utilisera automatiquement les crédits disponibles sur votre compte, alors assurez-vous d'avoir un solde suffisant. Si vos crédits sont insuffisants, nous vous enverrons un e-mail pour vous en informer. Vous pouvez vous désabonner à tout moment, sans tracas.",
    "close": "Fermer",
    "frequencies": {
      "every": "Tout les tirages",
      "over": "Lorsque le jackpot dépasse {amount}M",
    },
    "priceInfo": "pour chaque tirage",
    "errors": {
      "QUICKPICKS_NOT_VALID": "Nombre invalide de Quick Picks.",
      "LOTTERY_TYPE_NOT_VALID": "Type de loterie invalide, contactez le support.",
      "SUBSCRIPTION_ALREADY_EXIST": "Vous avez déjà un abonnement avec les mêmes valeurs.",
      "SUBSCRIPTION_NOT_FOUND": "Abonnement non trouvé.",
      "STATUS_NOT_VALID": "Status invalide, contactez le support.",
    },
    "status": {
      "active": "Active",
      "paused": "Suspendu",
      "fund": "Fond insufisant (ajouter des crédits)",
      "deleted": "Effacé",
    },

  },
  "banner": {
    "tooLate": "Trop tard pour l'achat de ce tirage mais revenez demain!",
    "waitingResult": "En attente de résultat, revenez plus tard.",
    "moreDetails": "Détails",
    "aboutTitle": "LOTTO AMIGO",
    "aboutDesc": "Participer aux deux plus grandes loteries américaines sans avoir besoin de voyager grâce à Lotto Amigo!",
    "freeTitle": "Loteries Gratuites",
    "freeDesc": "Participez à des groupes de loteries gratuites avec Lotto Amigo!",
    "acceptCCDesc": "Vous pouvez maintenant utiliser votre méthode de paiement préféré!",
    "acceptCCTitle": "Nous acceptons toutes les cartes de credit!",
    "nicole": "TEMPORAIREMENT FERMÉ: OURAGAN NICOLE",
    "showOffers": "Voir les offres",
  },
  "emailVerified": {
    "bannerTitle": "Svp, veuilliez confirmer votre adresse courriel. Si vous ne voyez pas le courriel, il pourrait être dans vos pourriels par accident.",
    "bannerAction": "(Envoyer encore)",
    "verifiedNotif": "Courriel vérifié, merci!",
    "emailSent": "Courriel de vérification envoyé."
  },
  "footer": {
    "aboutUsTitle": "À PROPOS DE NOUS",
    "aboutUsDesc": "Bienvenue sur Lotto Amigo (avec ses sous-domaines, contenu, marques et services, le « site »).  Lotto Amigo est une marque associée de BLACKDANES LLC, une société constituée en vertu des lois de la Floride, avec le numéro d'enregistrement 86-3829607"
  },
  "dialogs": {
    "areYourSure": "Êtes vous sure de vouloir effacer ce produit?",
    "productRemoving": "Retrait du produit de votre panier",
  },
  "session_warning": {
    "separate_ticket": "Les commandes avec plus de 3 combinaisons vont être séparées en plusieurs billets."
  },
  "promotionDialog": {
    "title": "Promotion",
  },
  "winningNumbers": "Numéros Gagnants",
  "date": "Date",
  "thankYou": "Je vous remercie",
  "contactUs": "Contactez nous",
  "social": "Social",
  "facebook": "Facebook",
  "twitter": "Twitter",
  "instagram": "Instagram",
  "youtube": "Youtube",
  "googlePlus": "Google Plus",
  "reports": "Rapports",
  "orders": "Commandes",
  "invoices": "Factures",
  "profile": "Profil",
  "products": "Des produits",
  "productAdd": "Ajout de produit",
  "goToSite": "Aller au site",
  "adminPanel": "Administration",
  "partnerPanel": "Partenaire",
  "edit": "Modifier",
  "images": {
    "bannerNameTicket": "/static/images/slider-5-name-ticket_fr.jpg",
    "blackFriday": "/static/images/blackfriday.jpg",
    "bannerPromo90": "/static/images/promo90_fr.jpg",
  },
  "suspended":
    "En raison de l'ouragan Milton, les ventes sont suspendues jusqu'à vendredi 11 octobre à 6h.",
}